'use client';

import FormControl from '@mui/material/FormControl';
import { FC } from 'react';
import { UseControllerProps, useController, useFormContext } from 'react-hook-form';
import MUITextField from '@mui/material/TextField';

type Props = {
  name: string;
  placeholder?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
};

const TextField: FC<Props> = (props: UseControllerProps & Props) => {
  const {
    formState: { errors },
  } = useFormContext();

  const { field } = useController(props);

  const helperText = <span>{errors[props.name]?.message as string}</span>;

  return (
    <FormControl fullWidth>
      <MUITextField
        id={props.name}
        placeholder={props.placeholder}
        variant="outlined"
        fullWidth
        size="small"
        {...field}
        error={!!errors[props.name]}
        autoComplete={props.autoComplete}
        multiline={props.multiline}
        rows={props.rows}
        // helperText={helperText}
        data-testid={`${props.name}-input`}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        slotProps={{
          input: {
            style: {
              padding: props?.rows && props.rows > 1 ? '0.5em 1em' : undefined,
              borderRadius: '2em',
            },
          },
        }}
      />
    </FormControl>
  );
};

export default TextField;
